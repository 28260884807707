$(document).on 'turbolinks:load', ->
  filter = (val) ->
    if val.length > 4
      $('label.checkbox:contains("' + val.toUpperCase() + '")').show()
      $('label.checkbox').not(':contains("' + val.toUpperCase() + '")').hide()

  $('input.procedimentos-filtro').keydown ->
    clearTimeout $.data(@, 'timer')
    wait = setTimeout filter($(@).val(), 700)
    $(@).data 'timer', wait


