/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

/* css */
import 'bootstrap/dist/css/bootstrap.css';

import 'jquery-ui.css';
import 'jquery.timepicker.css';
import 'dashboard-admin.scss';

import '../stylesheets/application.scss';

/* js */
/** vendor */
import 'rails-ujs'
import $ from 'jquery'
global.$ = $
global.jQuery = $

import 'bootstrap/dist/js/bootstrap.js';

import 'select2';
import 'select2/dist/js/i18n/pt-BR.js';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';

import 'bootstrap-datepicker/js/bootstrap-datepicker.js';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR.js';
import 'jquery.maskedinput/src/jquery.maskedinput.js';
import '../../../vendor/packs/jquery-ui/jquery.timepicker';

import '../../../vendor/packs/webcam/shutter.mp3';
import '../../../vendor/packs/webcam/webcam.swf';
import '../../../vendor/packs/webcam/webcam.js';

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

/** app */
import { ActionCable } from 'actioncable';
global.ActionCable = ActionCable;

import 'cable';
import 'check-all.js.coffee';
import 'clinica-simples.js.coffee';
import 'consultas.js.coffee';
import 'enderecos.js.coffee';
import 'filter.js.coffee';
import 'receitas.js.coffee';
import 'track_events.js.coffee';
