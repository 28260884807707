tonometriaWarningThreshold = 20

$(document).on "change", ".input-tonometria", (e) ->
  if $(@).val() >= tonometriaWarningThreshold
    $(@).parents(".form-group").addClass("warning")
    $(@).siblings(".help-block").text("mmHg - Atenção: pressão superior a 20mmHg!")
  else
    $(@).parents(".form-group").removeClass("warning")
    $(@).siblings(".help-block").text("mmHg")

$(document).on "changeDate", ".new_consulta .input-datepicker, .edit_consulta .input-datepicker", (ev) ->
  next = $(@).parents(".form-group").next().find(".input-datepicker")
  next.datepicker("update", $(@).val())
  next.trigger("changeDate")

$(document).on "change", "#consulta_medico_id", (e) ->
  $(@).parents('form').find('#consulta_solicitante_id').val($(@).val())

$(document).on "click", ".remove-execucao", (e) ->
  $(@).parents(".execucao-fields").find("input.destroy").val("1")
  $(@).parents(".execucao-fields").fadeOut()
  e.preventDefault()

$(document).on "click", ".add-execucao", (e) ->
  content = $(".new-execucao-fields").html()
  new_id = new Date().getTime()
  regexp = /[0-9]+(_|\]\[)new_execucao/g # replaces ID_new_execucao or [ID][new_execucao]
  $(".new-execucao-fields").before(content.replace(regexp, new_id))
  $(".consulta_execucoes_procedimento:visible:last select").select2()
  e.preventDefault()

$(document).on "change", ".consulta_execucoes_executante select", ->
  if ($(@).find("option:selected").text() == "CNES Terceirizado")
    $(@).parents(".execucao-fields").find(".cnes-terceirizado-field").fadeIn("fast")
    $(@).parents(".execucao-fields").find(".indicador-equipe-field").fadeOut("fast")
  else
    $(@).parents(".execucao-fields").find(".cnes-terceirizado-field").fadeOut("fast")
    $(@).parents(".execucao-fields").find(".indicador-equipe-field").fadeIn("fast")

  
