$(document).on 'turbolinks:load', ->
  if $("fieldset.receita").length
    $.data(document.body, 'confirmChange', true)

$(document).on "change", "fieldset.receita .check_boxes input", (e) ->
  if $.data(document.body, 'confirmChange')
    $.data(document.body, 'confirmChange', false)

    checked = $(@).attr('checked') == true
    if not confirm("Tem certeza que deseja mudar o tratamento do paciente?")
      $(@).attr('checked', checked)
      e.preventDefault()
