$(document).on 'turbolinks:load', ->
  if $('.enable-check-all').length
    check_all = $('<span class="checkbox check-all"><label><input type="checkbox">Todos</input></label>')
    check_all.on 'change', 'input', ->
      console.log($(this));
      $(this).parent().parent().parent().find(':checkbox').prop('checked', $(this).prop('checked'))

    $('.enable-check-all').each (input) ->
      $(this).find('.col-sm-9').prepend(check_all.clone(true))

