# per http://productforums.google.com/forum/#!topic/chrome/G1snYHaHSOc, this may help with the undefined errors
window.suggestmeyes_loaded = true;

$.fn.button.Constructor.DEFAULTS =
  loadingText: 'aguarde...'

$.extend($.fn.datepicker.defaults, (format: "dd/mm/yyyy", autoclose: true, endDate: new Date(), language: 'pt-BR', orientation: 'bottom left'))
$.extend($.fn.select2.defaults.set( "theme", "bootstrap" ));

$(document).on "click", ".btn-primary:not([data-no-loading])", ->
  $(@).button 'loading'

$(document).on 'turbolinks:load', ->

  $(".input-datepicker").datepicker()
  $(".input-timepicker").timepicker(timeFormat: 'H:i', maxTime: '8:00pm')

  $("input.phone").mask("?(99) 99999-9999")
  $("input.nascimento").mask("99/99/9999")
  $("input.cpf").mask("999.999.999-99")
  $("input.cep").mask("99999-999")

  $("form.form-horizontal:not(.form-inline) :input:visible:first").focus()

  $(".btn-primary").button('reset')

  $('.modal').modal('show')

  $("select.long-select:visible").select2(language: "pt-BR", placeholder: "Selecione", width: "75%")

  $(".cid-select-local").select2(
    language: "pt-BR",
    placeholder: "Escolha o CID", 
    width: "30%"
  )

  $(".cid-select-remote").select2(
    language: "pt-BR",
    placeholder: "Escolha o CID", 
    minimumInputLength: 3,
    width: "30%",
    ajax:
      url: "/cids.json",
      dataType: "json",
      cache: true,
      data: (params) ->
        q: params.term,
        procedimento_id: $(".cid-select-remote").data("procedimento_id")
      processResults: (data) ->
        results: ({ id: cid.id, text: cid.codigo_nome } for cid in data)
  )
